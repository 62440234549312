// @import '~@angular/material/theming';
@import 'material-icons/iconfont/material-icons.scss';

@import './assets/styles/controls.scss';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$primary: #bbcf71;
$secondary: #23a1af;

html {
  min-height: 100%;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

html {
  min-height: 100vh;
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

header {
  position: absolute;
  left: 21.1%;
}

app-info-modal {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-full-size {
  width: 100%;
}

.btn-big {
  height: 3em;
  font-size: larger;
}

.btn {
  border-radius: 1px !important;
  outline: none !important;
  box-shadow: none;
}

.btn-iv-primary {
  border: none;
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-iv-primary:hover {
  background-color: $primary;
  color: #ffffff;
  border: none;
  outline: none !important;
  box-shadow: none;
}

.btn-iv-primary:focus {
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-iv-primary-focus {
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-next-step, .btn-submit {
  float: right;
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}


.btn-next-step:hover,
.btn-submit:hover {
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-next-step:focus,
.btn-submit:focus {
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-next-step-valid {
  background-color: $primary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-next-step-invalid {
  background-color: $secondary;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-prev-step {
  float: right;
  background-color: #c5c5c5;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-prev-step:hover {
  background-color: #c5c5c5;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-prev-step:focus {
  background-color: #c5c5c5;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-prev-step-valid {
  background-color: #c5c5c5;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.btn-prev-step-invalid {
  background-color: #c5c5c5;
  color: #ffffff;
  outline: none !important;
  box-shadow: none;
}

.card-select-parent {
  border: 0 solid;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 0;
}

.card-select-item {
  border-radius: 1px !important;
}

.wizard-card {
  width: 60em;
  min-height: 40em;
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
  padding: 15px;
}

.wizard-card-header {
  position: relative;
  border: none !important;
  background-color: transparent;
}

.wizard-card-footer {
  border: none !important;
  background-color: transparent;
}

.wizard-card-body {
  border: none !important;
  background-color: transparent;
}

.wizard-col {
  padding-right: 4px;
  padding-left: 4px;
}

.font-1 {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.font-2 {
  font-family: 'Barlow', sans-serif;
  font-size: 54px;
  line-height: 54px;
}

.font-3 {
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.font-4 {
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  line-height: 24px;
}

.font-5 {
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  line-height: 18px;
}

.font-6 {
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 38px;
  line-height: 38px;
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

@media (min-width: 1200px) {
  .merkblatt-div {
    width: 60em;
    padding-left: 4em;
  }
}

@media (min-width: 1000px) {
  .font-1 {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .font-2 {
    font-family: 'Barlow', sans-serif;
    font-size: 54px;
    line-height: 54px;
  }

  .font-3 {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    line-height: 16px;
  }

  .font-4 {
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    line-height: 24px;
  }

  .font-5 {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    line-height: 18px;
  }

  .text-bold {
    font-weight: bold;
  }
}

@media (max-width: 1000px) {
  .font-1 {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .font-2 {
    font-family: 'Barlow', sans-serif;
    font-size: 54px;
    line-height: 54px;
  }

  .font-3 {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    line-height: 16px;
  }

  .font-4 {
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    line-height: 24px;
  }

  .font-5 {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    line-height: 18px;
  }

  .text-bold {
    font-weight: bold;
  }
}

@media (min-width: 450px) and (max-width: 775px) {
  .font-2 {
    font-family: 'Barlow', sans-serif;
    font-size: 34px !important;
    line-height: 34px !important;
  }
}

@media (max-width: 450px) {
  .font-1 {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .font-2 {
    font-family: 'Barlow', sans-serif;
    font-size: 22px !important;
    line-height: 22px !important;
    font-weight: bolder;
  }

  .font-3 {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    line-height: 16px;
  }

  .font-4 {
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    line-height: 24px;
  }

  .font-5 {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    line-height: 18px;
  }

  .text-bold {
    font-weight: bold;
  }
}

.shadow-IV {
  -webkit-box-shadow: 3px 5px 12px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 3px 5px 12px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 3px 5px 12px 0 rgba(0, 0, 0, 0.5);
}

.form-item {
  border-radius: 0;
  border: 0 solid white;
  height: 3em;
  font-size: larger;
  background-color: rgba(255, 255, 255, 0.8);
  color: #444;
  font-weight: 200;
  outline: 0 solid $primary !important;
  -webkit-appearance: 0px solid $primary !important;
  font-family: 'Barlow', sans-serif;
  box-shadow: none;
}

.form-item:focus {
  outline: 1px solid $primary !important;
  -webkit-appearance: 1px solid $primary !important;
  box-shadow: none;
}

.form-item:active {
  outline: 1px solid $primary !important;
  -webkit-appearance: 1px solid $primary !important;
  box-shadow: none;
}

.form-item-invalid {
  outline: 1px solid red !important;
}

.form-item:focus.form-item-invalid {
  outline: 1px solid red !important;
}

.error-message {
  color: red;
  float: left;
}

.form-group-IV {
  margin-bottom: 7px;
}

.progress-card {
  width: 60em;
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.8);
  border-color: transparent;
  padding: 15px 70px;
}

.progress {
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.8);
}

.progress-bar {
  background-color: $primary;
}

.switch-button-text {
  color: #fff !important;
  margin-top: 7px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.switch-button-icon {
  margin-top: -3px;
  font-size: 40px;
  float: left;
  color: #fff !important;
}

.switch-button-state-active {
  background-color: $secondary;
}

.switch-button-state-inactive {
  background-color: $primary;
}

.IV-checkbox {
  height: 100%;
}

.clear-background {
  background-color: transparent !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
}

.giant-button {
  height: 15em;
}

.giant-button-icon {
  font-size: 150px;
  color: $primary;
}

.fileList {
  overflow: auto;
  word-wrap: break-word;
  max-width: 100%;
}

ul {
  list-style-type: none;
}

.data-div {
  margin-top: 10px;
}

app-conclusion td {
  width: 50%;
  border: none !important;
}

.conclusion-card-header {
  background-color: $secondary;
  border-radius: 0 !important;
  color: #ffffff;
  padding: 0 !important;
}

.conclusion-card-header-button {
  padding: 0.75rem 1.25rem;
  width: 100%;
  height: 100%;
  text-decoration: none !important;
}

.conclusion-card-header-button:hover {
  color: #ffffff;
  text-decoration: none !important;
}

.conclusion-card-header-button:visited {
  color: #ffffff;
  text-decoration: none !important;
}

.conclusion-card {
  border-radius: 0 !important;
  border: none !important;
  background-color: rgba(255, 255, 255, 0.8);
}

.conclusion-card-button {
  color: #ffffff;
}

.td-left {
  text-align: right;
}

.td-right {
  text-align: left;
}

.conclusion-card-icon {
  font-size: 40px;
  float: left;
}

.conclusion-card-header-active {
  background-color: $primary;
  border-radius: 0 !important;
  color: #ffffff;
  padding: 0 !important;
}

@media only screen and (max-width: 667px) {
  .font-2 {
    font-size: 10vw;
  }
}

@media only screen and (max-width: 800px) {
  .fileList {
    font-size: 3vw;
  }
}

.modal-dialog,
.modal-body,
.modal-content {
  background-color: RGBA(255, 255, 255, 0.85);
  border-radius: 0;
  color: #444;
}

.modal-header {
  background-color: #ffffff;
  color: #444;
  border-radius: 0;
  border-bottom: 0;
}

.modal-header p {
  margin: auto;
  font-size: 45px;
}

.modal-content {
  height: 100%;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 50em;
  max-height: 50em;
}
